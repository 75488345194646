import { Session } from "@supabase/supabase-js";
import { AuthApiResponse, User } from "types/apiTypes";

export const inviteClient = async (email: string, type: string): Promise<AuthApiResponse> => {
  const res: AuthApiResponse = await fetch("/api/auth/invite", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email: email, type: type })
  }).then((res) => res.json());

  return res;
};

export const sendMagicLinkEmail = async ({
  email,
  type,
  redirectUrl,
  workspaceId,
  shouldCreateUser
}: {
  email: string;
  type: string;
  redirectUrl?: string | null;
  workspaceId?: string | null;
  shouldCreateUser?: boolean;
}): Promise<AuthApiResponse> => {
  const res: AuthApiResponse = await fetch("/api/auth/p_send_magic_link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email: email, type: type, redirect_url: redirectUrl, workspaceId, shouldCreateUser })
  }).then((res) => res.json());

  return res;
};

export const sendPhoneOTP = async (
  phone: string,
  workspaceId?: string,
  shouldCreateUser?: boolean
): Promise<AuthApiResponse> => {
  const res: AuthApiResponse = await fetch("/api/auth/p_send_phone_otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ phone: phone, workspaceId, shouldCreateUser })
  }).then((res) => res.json());

  return res;
};

export const verifyPhoneOTP = async ({
  phone,
  token
}: {
  phone: string;
  token: string;
}): Promise<AuthApiResponse & { session: Session }> => {
  const res = await fetch("/api/auth/p_verify_otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ phone: phone, token: token })
  }).then((res) => res.json());

  return res;
};

export const verifyEmailOTP = async ({
  email,
  token
}: {
  email: string;
  token: string;
}): Promise<AuthApiResponse & { session: Session }> => {
  const res = await fetch("/api/auth/p_verify_otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email: email, token: token })
  }).then((res) => res.json());

  return res;
};

export const validateAuthToken = async (token: string): Promise<AuthApiResponse> => {
  const result = await fetch(`/api/auth/p_validate_token?token=${token}`).then((res) => res.json());
  return result;
};
