import { Loader as SpinnerIcon } from "lucide-react";
import clsx from "clsx";

const Loader = ({
  className,
  wrapperClassName,
  hidden = false
}: {
  className?: string;
  wrapperClassName?: string;
  hidden?: boolean;
}) => {
  if (hidden) {
    return null;
  }

  return (
    <div className={clsx("flex h-full w-full items-center justify-center", wrapperClassName)}>
      <SpinnerIcon
        role="status"
        className={clsx("spinner-border inline-block h-8 w-8 animate-spin rounded-full", className)}
      />
    </div>
  );
};
export default Loader;
