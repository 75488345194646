import { useCallback, useRef } from "react";
import { inviteClient, sendMagicLinkEmail, sendPhoneOTP } from "lib/authApi";
import useErrorLogger from "hooks/useErrorLogger";
import { User } from "types/apiTypes";
import { ERROR_TYPES, TOAST_TYPE } from "utils/constants";
import toast, { ToastId } from "utils/toast";
import { RecordItem } from "types/common";

const useInviteUser = (hideToast = false) => {
  const progressToastId = useRef<ToastId | null>(null);
  const { logError } = useErrorLogger();

  const inviteUser = useCallback(
    async (user: User) => {
      if (!user.email) {
        toast.error("Failed to send Invite, email not present", {
          autoClose: 2000
        });
        return;
      }

      if (!user.type) {
        toast.error("Failed to send Invite, user role not present", {
          autoClose: 2000
        });
        return;
      }
      progressToastId.current = toast.success("Sending Invite...", {
        autoClose: false
      });
      try {
        const res = await inviteClient(user.email, user.type);
        if (res.success) {
          toast.update(progressToastId.current, "Invite Sent", {
            type: TOAST_TYPE.SUCCESS,
            autoClose: 2000
          });
        } else {
          toast.update(progressToastId.current, res.error, {
            type: TOAST_TYPE.ERROR,
            autoClose: 2000
          });
        }
      } catch (err) {
        logError({
          error: err as Error,
          source: "useInviteUser - inviteUser",
          message: "Failed to send Invite",
          type: ERROR_TYPES.USER_AUTH,
          url: window.location.href,
          additionalInfo: {
            user
          }
        });
        toast.update(progressToastId.current, "Failed to send Invite", {
          type: TOAST_TYPE.ERROR,
          autoClose: 2000
        });
      }
    },
    [logError]
  );

  const sendMagicLinkToUser = useCallback(
    async (
      {
        user,
        redirectUrl,
        shouldCreateUser
      }: { user: RecordItem; redirectUrl?: string | null; shouldCreateUser?: boolean },
      type = "email"
    ) => {
      if (type === "email" && !user.email) {
        toast.error("Failed to send Magic Link, email not present", {
          autoClose: 2000
        });
        return false;
      }

      if (type === "phone" && !user.phone) {
        toast.error("Failed to send OTP, phone number not present", {
          autoClose: 2000
        });
        return false;
      }

      const channel = type === "email" ? "Magic Link" : "OTP";

      if (!hideToast) {
        progressToastId.current = toast.success(`Sending ${channel}...`, {
          autoClose: false
        });
      }

      try {
        let res;
        if (type === "email" && user.email) {
          res = await sendMagicLinkEmail({
            email: user.email,
            type: user.type,
            redirectUrl,
            workspaceId: user.workspaceId,
            shouldCreateUser
          });
        } else if (type === "phone" && user.phone) {
          res = await sendPhoneOTP(user.phone, user.workspaceId, shouldCreateUser);
        }

        if (res?.success) {
          if (!hideToast && progressToastId.current) {
            toast.update(progressToastId.current, `${channel} Sent`, {
              type: TOAST_TYPE.SUCCESS,
              autoClose: 2000
            });
          }
          return true;
        } else {
          if (!hideToast && progressToastId.current) {
            toast.update(progressToastId.current, res?.error, {
              type: TOAST_TYPE.ERROR,
              autoClose: 2000
            });
          }
          logError({
            error: {
              name: "SendMagicLinkError",
              message: res?.error || "Failed to send magic link"
            },
            source: "useInviteUser - sendMagicLinkToUser",
            message: `Failed to send ${channel}`,
            type: ERROR_TYPES.USER_AUTH,
            url: window.location.href,
            additionalInfo: {
              user,
              redirectUrl
            }
          });
          return false;
        }
      } catch (err) {
        logError({
          error: err as Error,
          source: "useInviteUser - sendMagicLinkToUser",
          message: `Failed to send ${channel}`,
          type: ERROR_TYPES.USER_AUTH,
          url: window.location.href,
          additionalInfo: {
            user,
            redirectUrl
          }
        });
        if (!hideToast && progressToastId.current) {
          toast.update(progressToastId.current, `Failed to send ${channel}`, {
            type: TOAST_TYPE.ERROR,
            autoClose: 2000
          });
        }
        return false;
      }
    },
    [hideToast, logError]
  );

  return {
    inviteUser,
    sendMagicLink: sendMagicLinkToUser
  };
};

export default useInviteUser;
